export const validity = {
    mounted(el, binding) {
        el.setAttribute("oninvalid", `setCustomValidity('${binding.value}')`);
        el.setAttribute("oninput", `setCustomValidity('')`);
    }
};

export const emailValidity = {
    mounted(el) {
        el.setAttribute("pattern", "(?![+_.\\-])((?![+_.\\-][+_.\\-])[+a-zA-Z\\d_.\\-]){0,63}[a-zA-Z\\d]@((?!-)((?!--)[a-zA-Z\\d\\-]){0,63}[a-zA-Z\\d]\\.){1,2}([a-zA-Z]{2,14}\\.)?[a-zA-Z]{2,14}");
        el.setAttribute("oninvalid", `setCustomValidity('Enter valid email')`);
        el.setAttribute("oninput", `setCustomValidity('')`);
    }
};

export default {
    directives: {
        validity,
        emailValidity
    }
};
